import { CheckOutProductsInterface } from '../interfaces/checkout-products.interface';
import {
  OrderInterface,
  OrderItems,
  PlaceOrderInterface,
} from '../interfaces/orders.interface';
import { ShopsInterface } from '../interfaces/shops.interface';
import {
  AddStocksInterface,
  ReceiveStockInterface,
  SendStocksInterface,
} from '../interfaces/stocks.interface';
import { GetUserInterface } from '../interfaces/user.interface';

export function getShopId(shops: ShopsInterface[], shopId: string | undefined) {
  let selectedShop!: ShopsInterface;

  shops?.every((value) => {
    if (value?._id === shopId) {
      selectedShop = value;
      return false;
    }

    return true;
  });

  return selectedShop?._id;
}

export function constructOrderPayload(
  initialItems: PlaceOrderInterface,
  checkoutBill: CheckOutProductsInterface[],
  userInfo: GetUserInterface | null,
  selectedShop: ShopsInterface | null
) {
  const payload: OrderInterface = {
    userId: userInfo?._id ? userInfo?._id : '',
    shopId: selectedShop?._id ? selectedShop?._id : '',
    items: [],
    totalAmount: initialItems?.totalAmount,
    discountAmount: initialItems?.discountAmount,
    status: initialItems?.status,
    paymentMode: initialItems?.paymentMode,
    comment: initialItems?.comment,
  };

  checkoutBill?.forEach((item) => {
    const newQuantity = {
      quantityUnit: item?.item?.quantityUnit,
      quantity: parseFloat(item?.quantitySelected),
    };

    if (item?.item?.quantityUnit === 'gram') {
      newQuantity.quantityUnit = 'kg';
      newQuantity.quantity = parseFloat(item?.quantitySelected) / 1000;
    }

    if (item?.item?.quantityUnit === 'amount') {
      if (item?.item?.itemPrice?.pricePerKg) {
        newQuantity.quantityUnit = 'kg';
        newQuantity.quantity =
          parseFloat(item?.quantitySelected) /
          item?.item?.itemPrice?.pricePerKg;
      } else if (item?.item?.itemPrice?.pricePerPiece) {
        newQuantity.quantityUnit = 'piece';
        newQuantity.quantity =
          parseFloat(item?.quantitySelected) /
          item?.item?.itemPrice?.pricePerPiece;
      }
    }

    payload.items.push({
      productId: item?.item?._id,
      quantity: parseFloat(newQuantity.quantity?.toFixed(3)),
      quantityUnit: newQuantity.quantityUnit === 'kg' ? 'kg' : 'piece',
    });
  });

  return payload;
}

export function constructSendStockPayload(
  initialItems: {
    containerNo: string;
    receiverShopName: string;
    itemDescription?: string;
    comment?: string;
  },
  checkoutBill: CheckOutProductsInterface[],
  userInfo: GetUserInterface | null,
  selectedShop: ShopsInterface | null,
  allShops: ShopsInterface[]
) {
  const payload: SendStocksInterface = {
    containerNo: initialItems?.containerNo ? initialItems?.containerNo : '',
    senderId: userInfo?._id ? userInfo?._id : '',
    senderShopId: selectedShop?._id ? selectedShop?._id : '',
    receiverShopId: '',
    items: [],
    status: 'Pending',
    itemDescription: initialItems?.itemDescription
      ? initialItems?.itemDescription
      : '',
    comment: initialItems?.comment ? initialItems?.comment : '',
  };

  checkoutBill?.forEach((item) => {
    const newQuantity = {
      quantityUnit: item?.item?.quantityUnit,
      quantity: parseFloat(item?.quantitySelected),
    };

    if (item?.item?.quantityUnit === 'gram') {
      newQuantity.quantityUnit = 'kg';
      newQuantity.quantity = parseFloat(item?.quantitySelected) / 1000;
    }

    payload.items.push({
      productId: item?.item?._id,
      quantity: newQuantity.quantity,
      quantityUnit: newQuantity.quantityUnit === 'kg' ? 'kg' : 'piece',
    });
  });

  allShops?.every((shop) => {
    if (shop?.shopName === initialItems?.receiverShopName) {
      payload.receiverShopId = shop._id;
      return false;
    }

    return true;
  });

  return payload;
}

export function constructReceiveStockPayload(
  action: ReceiveStockInterface,
  checkoutBill: CheckOutProductsInterface[]
) {
  const items: OrderItems[] = [];
  checkoutBill?.forEach((item) => {
    const newQuantity = {
      quantityUnit: item?.item?.quantityUnit,
      quantity: parseFloat(item?.quantitySelected),
    };

    if (item?.item?.quantityUnit === 'gram') {
      newQuantity.quantityUnit = 'kg';
      newQuantity.quantity = parseFloat(item?.quantitySelected) / 1000;
    }

    items.push({
      productId: item?.item?._id,
      quantity: newQuantity.quantity,
      quantityUnit: newQuantity.quantityUnit === 'kg' ? 'kg' : 'piece',
    });
  });

  const modifiedPayload = { ...action, items };

  return modifiedPayload;
}

export function constructInvoice(
  checkoutBill: CheckOutProductsInterface[],
  selectedShop: ShopsInterface | null
) {
  const totalAmount = calculateTotalAmount(checkoutBill);
  const items: any = [];

  checkoutBill?.forEach((value) => {
    const quantity =
      value?.item?.quantityUnit === 'gram'
        ? (parseFloat(value.quantitySelected) / 1000).toString()
        : value?.quantitySelected;

    const quantityUnit =
      value?.item?.quantityUnit === 'gram' ? 'kg' : value?.item?.quantityUnit;

    const subTotal =
      quantityUnit === 'kg'
        ? value?.item?.itemPrice?.pricePerKg
          ? (value?.item?.itemPrice?.pricePerKg * parseFloat(quantity)).toFixed(
              2
            )
          : ''
        : value?.item?.itemPrice?.pricePerPiece
        ? (
            value?.item?.itemPrice?.pricePerPiece * parseFloat(quantity)
          ).toFixed(2)
        : '';

    const item = {
      quantity: quantity,
      quantityUnit: quantityUnit,
      itemName: value?.item?.itemName,
      subTotal: subTotal?.toString(),
    };

    items.push(item);
  });

  const invoice = {
    totalAmount: totalAmount,
    items: items,
    orderNumber: generateOrderId(),
    shopName: selectedShop?.shopName
      ? selectedShop?.shopName === 'AVR'
        ? 'ATITHI VIHAR'
        : selectedShop?.shopName === 'ALK'
        ? 'ALAKANANDA'
        : 'ATITHI SWEETS'
      : '',
    shopAddress:
      (selectedShop?.shopName === 'AVR' || selectedShop?.shopName === 'ALK'
        ? 'Madhusudan Market, Rourkela, Odisha 769001'
        : 'Shop No. S/28, NAC Market, Koelnagar, Rourkela, Odisha 769014') ??
      '',
  };

  return invoice;
}

function calculateTotalAmount(bill: CheckOutProductsInterface[]) {
  let totalAmount = 0;
  let calculatedAmountPerItem = 0;
  let oneItemEmpty = false;

  bill?.forEach((item) => {
    if (item?.quantitySelected) {
      if (item?.item?.quantityUnit === 'kg') {
        if (item?.item?.itemPrice?.pricePerKg?.toString()) {
          calculatedAmountPerItem =
            parseFloat(item?.item?.itemPrice?.pricePerKg?.toString()) *
            parseFloat(item?.quantitySelected?.toString());

          totalAmount += calculatedAmountPerItem;
        }
      } else if (item?.item?.quantityUnit === 'gram') {
        if (item?.item?.itemPrice?.pricePerKg?.toString()) {
          calculatedAmountPerItem =
            parseFloat(item?.item?.itemPrice?.pricePerKg?.toString()) *
            parseFloat(item?.quantitySelected?.toString());

          calculatedAmountPerItem /= 1000;
          totalAmount += calculatedAmountPerItem;
        }
      } else {
        if (item?.item?.itemPrice?.pricePerPiece?.toString()) {
          calculatedAmountPerItem =
            parseFloat(item?.item?.itemPrice?.pricePerPiece?.toString()) *
            parseFloat(item?.quantitySelected?.toString());

          totalAmount += calculatedAmountPerItem;
        }
      }
    } else {
      oneItemEmpty = true;
    }
  });

  return oneItemEmpty ? '-' : totalAmount?.toString();
}

function generateOrderId(length = 8): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return `ORD-${result}`;
}

export function constructAddStockPayload(
  checkoutBill: CheckOutProductsInterface[],
  selectedShop: ShopsInterface | null
) {
  const payload: AddStocksInterface = {
    shopId: selectedShop?._id ? selectedShop?._id : '',
    items: [],
  };

  checkoutBill?.forEach((item) => {
    const newQuantity = {
      quantityUnit: item?.item?.quantityUnit,
      quantity: parseFloat(item?.quantitySelected),
    };

    if (item?.item?.quantityUnit === 'gram') {
      newQuantity.quantityUnit = 'kg';
      newQuantity.quantity = parseFloat(item?.quantitySelected) / 1000;
    }

    payload.items.push({
      productId: item?.item?._id,
      quantity: newQuantity.quantity,
      quantityUnit: newQuantity.quantityUnit === 'kg' ? 'kg' : 'piece',
    });
  });

  return payload;
}
