<div class="topNavContainer">
  <button class="green-transparent-button" (click)="changeLeftNavState()">
    <i class="bi bi-list"></i>
  </button>

  <div class="input-group">
    <input
      #searchBar
      type="text"
      class="form-control"
      placeholder="Enter Product Name or Id"
      aria-label="Enter Product Name or Id"
      aria-describedby="searchBar"
      (input)="searchItem(searchBar.value)"
    />
    <div class="input-group-append">
      <button
        class="input-group-text"
        id="searchBar"
        (click)="clearSearchBar()"
      >
        CLEAR
      </button>
    </div>
  </div>

  @defer (on timer(2000)) {
  <div class="green-transparent-button userAndShopInfo">
    {{ (userInfo$ | async)?.name }} :
    {{
      (selectedShop$ | async)?.shopName === 'AVR'
        ? 'ATITHI VIHAR'
        : (selectedShop$ | async)?.shopName === 'ALK'
        ? 'ALAKANANDA'
        : 'ATITHI SWEETS'
    }}
  </div>
  }

  <div>
    <button class="refreshButton green-transparent-button" (click)="refresh()">
      <i class="bi bi-arrow-clockwise"></i>
    </button>
    <button class="green-transparent-button" (click)="signOut()">
      Sign Out
    </button>
  </div>
</div>
