export const ORDER_OF_SUBCATEGORY = [
  {
    categoryName: 'SWEETS',
    order: ['CHENNA', 'KHOYA', 'PACKET AND TIN', 'OTHERS'],
  },
  {
    categoryName: 'SNACKS',
    order: [],
  },
  {
    categoryName: 'COLD DRINK',
    order: [],
  },
  {
    categoryName: 'CHOCOLATE',
    order: ['CADBURY', 'AMUL', 'HERSHEYS'],
  },
  {
    categoryName: 'MIXTURE',
    order: [],
  },
  {
    categoryName: 'MILK ITEMS',
    order: [],
  },
  {
    categoryName: 'DESSERT',
    order: [],
  },
];
