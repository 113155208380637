import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Signal,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import {
  changeLeftNavState,
  searchBarValue,
  signOut,
} from 'src/app/ngrx/app.actions';
import { Observable, catchError, map, of } from 'rxjs';
import { GetUserInterface } from 'src/app/interfaces/user.interface';
import {
  selectAllProducts,
  selectSelectedCategory,
  selectSelectedShop,
  selectUserInfo,
} from 'src/app/ngrx/app.selectors';
import { ProductsInterface } from 'src/app/interfaces/products.interface';
import { toSignal } from '@angular/core/rxjs-interop';
import { ShopsInterface } from 'src/app/interfaces/shops.interface';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-top-nav',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './top-nav.component.html',
  styleUrl: './top-nav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavComponent {
  private store = inject(Store);
  private swUpdate = inject(SwUpdate);

  @ViewChild('searchBar') searchBar: ElementRef<HTMLInputElement> | null = null;

  userInfo$: Observable<GetUserInterface | null> =
    this.store.select(selectUserInfo);

  selectedShop$: Observable<ShopsInterface | null> =
    this.store.select(selectSelectedShop);

  selectedCategorySignal = toSignal(this.store.select(selectSelectedCategory));

  allProducts$: Observable<ProductsInterface[] | null> = this.store
    .select(selectAllProducts)
    ?.pipe(
      map((allItems) => {
        const products: ProductsInterface[] = [];
        allItems?.forEach((value) => {
          if (value?.products?.length > 0) {
            value?.products?.forEach((item) => {
              products?.push({
                _id: item?._id,
                itemName: item?.itemName,
                categoryId: value?._id,
                categoryName: value?.categoryName,
                quantityUnit: item?.quantityUnit,
                itemPrice: item?.itemPrice,
                itemDescription: item?.itemDescription,
                imageLink: item?.imageLink,
                otherShopPrices: item?.otherShopPrices,
              });
            });
          } else {
            value?.subCategory?.forEach((val) => {
              if (val?.products?.length && val?.products?.length > 0) {
                val?.products?.forEach((item) => {
                  products?.push({
                    _id: item?._id,
                    itemName: item?.itemName,
                    categoryId: value?._id,
                    categoryName: value?.categoryName,
                    subCategoryId: val?._id,
                    subCategoryName: val?.categoryName,
                    quantityUnit: item?.quantityUnit,
                    itemPrice: item?.itemPrice,
                    itemDescription: item?.itemDescription,
                    imageLink: item?.imageLink,
                    otherShopPrices: item?.otherShopPrices,
                  });
                });
              }
            });
          }
        });
        return products;
      }),
      catchError((error) => {
        console.error('Error fetching products', error);
        return of(null);
      })
    );

  allProductsSignal: Signal<ProductsInterface[] | null | undefined> = toSignal(
    this.allProducts$
  );

  signOut() {
    this.store.dispatch(signOut());
  }

  changeLeftNavState() {
    this.store.dispatch(changeLeftNavState());
  }

  searchItem(value: string) {
    if (value?.length > 0) {
      const searchResult = this.fuzzySearchProducts(
        this.allProductsSignal(),
        value
      );
      if (searchResult) {
        this.store.dispatch(searchBarValue({ searchBarValue: searchResult }));
      }
    } else {
      this.clearSearchBar();
    }
  }

  clearSearchBar() {
    this.store.dispatch(searchBarValue({ searchBarValue: [] }));
    if (this.searchBar) {
      this.searchBar.nativeElement.value = '';
    }
  }

  refresh() {
    this.checkForUpdates();
  }

  fuzzySearchProducts = (
    products: ProductsInterface[] | null | undefined,
    searchTerm: string
  ): ProductsInterface[] | null => {
    if (!products) return null;

    return products.filter((product) => {
      // Check product belongs to selected category
      const belongsToSelectedCategory =
        product?.categoryId === this.selectedCategorySignal()?.categoryId;

      // Check itemName
      const matchesItemName = this.fuzzySearch(product.itemName, searchTerm);

      // Check pricePerPiece if defined
      const matchesPricePerPiece =
        product.itemPrice.pricePerPiece !== undefined &&
        product?.itemPrice?.pricePerPiece?.toString()?.includes(searchTerm);

      // Check pricePerKg if defined
      const matchesPricePerKg =
        product.itemPrice.pricePerKg !== undefined &&
        product?.itemPrice?.pricePerKg?.toString()?.includes(searchTerm);

      // Check categoryName
      const matchesCategoryName =
        product?.categoryName &&
        this.fuzzySearch(product.categoryName, searchTerm);

      // Check subCategoryName
      const matchesSubCategoryName =
        product?.subCategoryName &&
        this.fuzzySearch(product.subCategoryName, searchTerm);

      return (
        belongsToSelectedCategory &&
        (matchesItemName ||
          matchesPricePerPiece ||
          matchesPricePerKg ||
          matchesCategoryName ||
          matchesSubCategoryName)
      );
    });
  };

  fuzzySearch = (text: string, search: string): boolean => {
    const searchLower = search.toLowerCase();
    const textLower = text.toLowerCase();
    let i = 0;
    for (let j = 0; j < textLower.length; j++) {
      if (searchLower[i] === textLower[j]) i++;
      if (i === searchLower.length) return true;
    }
    return false;
  };

  checkForUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate
        .checkForUpdate()
        .then((value) => {
          if (value) {
            console.log('New update available.');
            this.activateUpdate();
          } else {
            console.log('No update available.');
            window.location.href = '/';
          }
        })
        .catch((err) => {
          console.error('Error checking for updates:', err);
          window.location.href = '/';
        });
    } else {
      window.location.href = '/';
    }
  }

  activateUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate
        .activateUpdate()
        .then(() => {
          console.log('Update activated.');
          window.location.href = '/';
        })
        .catch((err) => {
          console.error('Error activating update:', err);
          window.location.href = '/';
        });
    }
  }
}
