import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
  isDevMode,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideStore } from '@ngrx/store';
import { appReducer } from './ngrx/app.reducer';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { OktaAuthModule } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from './interceptors/auth.interceptor';
import { provideEffects } from '@ngrx/effects';
import { appEffects } from './ngrx/app.effects';
import * as localforage from 'localforage';
import { provideAnimations } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { provideServiceWorker } from '@angular/service-worker';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { MAT_DATE_LOCALE } from '@angular/material/core';

const oktaConfig = {
  issuer: environment.issuer,
  clientId: environment.clientId,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  tokenManager: {
    storage: 'cookie',
  },
  cookies: {
    secure: true,
  },
};

localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'myApp',
  version: 1,
  storeName: 'myStore',
});

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      OktaAuthModule.forRoot({
        oktaAuth: new OktaAuth(oktaConfig),
      })
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideStore({ app: appReducer }),
    provideEffects(appEffects),
    provideStoreDevtools(),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideAnimations(),
    DatePipe,
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode() || isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideCharts(withDefaultRegisterables()),
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
};
