import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent implements OnInit {
  private oktaAuth = inject(OKTA_AUTH);
  private oktaAuthStateService = inject(OktaAuthStateService);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);

  isAuthenticated$: Observable<boolean | undefined> =
    this.oktaAuthStateService.authState$?.pipe(
      map((value) => value?.isAuthenticated)
    );

  ngOnInit(): void {
    this.isAuthenticated$
      .pipe(takeUntilDestroyed(this.destroyRef))
      ?.subscribe((value) => {
        try {
          if (!value) {
            this.signIn();
          } else if (value) {
            this.router.navigate(['/dashboard']);
          }
        } catch (error) {
          console.log('Sign In Error', error);
          this.oktaAuth.clearStorage();
          this.router.navigate(['/']);
        }
      });
  }

  async signIn() {
    try {
      await this.oktaAuth.signInWithRedirect();
    } catch (error) {
      console.log('Sign In Error', error);
    }
  }
}
