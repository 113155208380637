import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectUserInfo } from 'src/app/ngrx/app.selectors';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-left-nav',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './left-nav.component.html',
  styleUrl: './left-nav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftNavComponent {
  private store = inject(Store);

  selectedUserRole$ = this.store
    .select(selectUserInfo)
    ?.pipe(map((userInfo) => userInfo?.role));
}
