import { Route } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { LoginPageComponent } from './features/login/login-page.component';
import { authGuard } from './guards/auth.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginPageComponent,
  },
  {
    path: 'login/callback',
    pathMatch: 'full',
    component: OktaCallbackComponent,
  },
  {
    path: 'dashboard',
    pathMatch: 'full',
    loadComponent: () =>
      import('./features/dashboard/dashboard.component').then(
        (c) => c.DashboardComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'orders',
    loadComponent: () =>
      import('./features/orders/orders.component').then(
        (c) => c.OrdersComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'stocks',
    loadComponent: () =>
      import('./features/stocks/stocks.component').then(
        (c) => c.StocksComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'products',
    loadComponent: () =>
      import('./features/products/products.component').then(
        (c) => c.ProductsComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'bills',
    loadComponent: () =>
      import('./features/bills/bills.component').then(
        (c) => c.BillsComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'analytics',
    loadComponent: () =>
      import('./features/analytics/analytics-page.component').then(
        (c) => c.AnalyticsPageComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];
