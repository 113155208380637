export const USER_API_ENDPOINTS = {
  getAllUsers: '/users',
  getUserById: '/:id',
  createUser: '/users',
  updateUser: '/:id',
  deleteUser: '/:id',
  login: '/users/login',
  logout: '/users/logout',

  getAllShops: '/shops',
  getAllProducts: '/categories/with-products',

  createCategory: '/categories',
  updateCategory: '/categories/:id',
  deleteCategory: '/categories/:id',

  createProduct: '/products',
  updateProduct: '/products/:id',
  deleteProduct: '/products/:id',

  getAllStocks: '/stocks',
  getAllStocksByShopId: '/stocks?shopId=:id',
  getAllSentStock: '/sent-stock',
  sendStock: '/sent-stock',
  addStock: '/stocks/add-stock-in-shop',
  updateStockById: '/stocks/:id',
  receivedStock: '/received-stock',
  deleteStockById: '/stocks/:id',

  getAllOrders: '/orders',
  getAllOrdersByUserId: '/orders/user/:id',
  updateOrder: '/orders/:id',
  placeOrder: '/orders',

  fileUpload: '/files/upload',
  deleteFile: '/files/delete',

  getAllBills: '/bills',
  getAllBillsByShopId: '/bills/shop/:id',
  createBill: '/bills',
  updateBill: '/bills/:id',
  deleteBill: '/bills/:id',
};
