import { createReducer, on } from '@ngrx/store';
import { AppState } from '../interfaces/app.state';
import {
  changeLeftNavState,
  addCheckoutBillItem,
  searchBarValue,
  deleteCheckoutBillItem,
  modifyCheckoutBill,
  getAllShopsSuccess,
  getAllProductsSuccess,
  getAllStocksSuccess,
  changeSelectedCategory,
  changeSelectedSubCategory,
  getAllOrdersSuccess,
  holdOrder,
  switchOrder,
  placeOrderSuccess,
  orderDateChanged,
  sendStockSuccess,
  getAllSentStockSuccess,
  getAllUsersSuccess,
  uploadFileSuccess,
  getAllBillsSuccess,
  addStockSuccess,
  billDateChanged,
  getAllOrdersByUserIdSuccess,
  receiveStockSuccess,
  deleteStockSuccess,
  updateStockSuccess,
} from './app.actions';
import {
  constructCheckoutBill,
  deleteProductFromCheckoutBill,
  getAllShops,
  getInitialSelectedCategory,
  getUserInfo,
  holdCheckoutBill,
  incrementOrDecrementCheckoutBill,
  modifyHoldOrder,
  returnBillsOnDateChange,
  returnOrdersOnDateChange,
  sortProductsArray,
  updateStock,
} from '../utils/reducer-functions';

export const initialState: AppState = {
  leftNavState: false,
  searchBarValue: [],
  checkoutBill: [],
  holdOrders: [],

  userInfo: null,
  allUsers: null,
  shops: [],
  selectedShop: null,
  allProducts: null,
  allStocks: null,
  allSentStock: null,

  selectedCategory: null,
  selectedSubCategory: null,
  orders: null,
  fileUploadProgress: 0,
  fileUploadUrl: null,
  bills: [],
  invoiceState: null,
};

export const appReducer = createReducer(
  initialState,
  on(changeLeftNavState, (state) => ({
    ...state,
    leftNavState: !state.leftNavState,
  })),
  on(changeSelectedCategory, (state, actions) => ({
    ...state,
    selectedCategory: actions,
    selectedSubCategory: actions?.selectedSubCategoryId
      ? actions?.selectedSubCategoryId
      : '',
  })),
  on(changeSelectedSubCategory, (state, actions) => ({
    ...state,
    selectedSubCategory: actions?.categoryId,
  })),
  on(searchBarValue, (state, actions) => ({
    ...state,
    searchBarValue: actions.searchBarValue,
  })),
  on(addCheckoutBillItem, (state, actions) => ({
    ...state,
    checkoutBill: [
      ...constructCheckoutBill(state?.checkoutBill, actions?.order),
    ],
  })),
  on(deleteCheckoutBillItem, (state, actions) => ({
    ...state,
    checkoutBill: [
      ...deleteProductFromCheckoutBill(state?.checkoutBill, actions?.item),
    ],
  })),
  on(modifyCheckoutBill, (state, actions) => ({
    ...state,
    checkoutBill: incrementOrDecrementCheckoutBill(
      state?.checkoutBill,
      actions
    ),
  })),
  on(holdOrder, (state) => ({
    ...state,
    holdOrders: holdCheckoutBill(state?.checkoutBill, state?.holdOrders),
    checkoutBill: [],
  })),
  on(switchOrder, (state, actions) => ({
    ...state,
    checkoutBill: state?.holdOrders[actions?.index]?.bill,
    holdOrders: modifyHoldOrder(state?.holdOrders, actions?.index),
  })),
  on(getAllUsersSuccess, (state, actions) => ({
    ...state,
    allUsers: actions?.users,
    userInfo: getUserInfo(actions.users, actions?.userEmail),
  })),
  on(getAllShopsSuccess, (state, actions) => ({
    ...state,
    shops: getAllShops(actions?.shops),
    selectedShop: state?.userInfo?.shopId ? state?.userInfo?.shopId : null,
  })),
  on(getAllProductsSuccess, (state, actions) => ({
    ...state,
    allProducts: sortProductsArray(actions?.products, state?.selectedShop),
    selectedCategory: getInitialSelectedCategory(actions?.products),
    selectedSubCategory: getInitialSelectedCategory(actions?.products)
      ?.subCategoryId,
  })),
  on(getAllStocksSuccess, (state, actions) => ({
    ...state,
    allStocks: actions?.response,
  })),
  on(getAllOrdersSuccess, getAllOrdersByUserIdSuccess, (state, actions) => ({
    ...state,
    orders: actions?.orders,
  })),
  on(placeOrderSuccess, (state) => ({
    ...state,
    checkoutBill: [],
  })),
  on(orderDateChanged, (state) => ({
    ...state,
    orders: returnOrdersOnDateChange(state?.orders),
  })),
  on(sendStockSuccess, (state) => ({
    ...state,
    checkoutBill: [],
  })),
  on(addStockSuccess, (state) => ({
    ...state,
    checkoutBill: [],
  })),
  on(updateStockSuccess, (state, action) => ({
    ...state,
    allStocks: updateStock(state?.allStocks, action?.response),
  })),
  on(getAllSentStockSuccess, (state, actions) => ({
    ...state,
    allSentStock: actions?.response,
  })),
  on(receiveStockSuccess, (state) => ({
    ...state,
    checkoutBill: [],
  })),
  on(deleteStockSuccess, (state, action) => ({
    ...state,
    allStocks:
      state?.allStocks?.filter((stock) => stock?._id !== action?.id) ??
      state?.allStocks,
  })),
  on(uploadFileSuccess, (state, { url }) => {
    return {
      ...state,
      fileUploadUrl: url,
      fileUploadProgress: null,
    };
  }),
  on(getAllBillsSuccess, (state, actions) => ({
    ...state,
    bills: actions?.bills,
  })),
  on(billDateChanged, (state) => ({
    ...state,
    bills: returnBillsOnDateChange(state?.bills),
  }))
);
