import * as localforage from 'localforage';
import { CheckOutProductsInterface } from '../interfaces/checkout-products.interface';
import { GetUserInterface } from '../interfaces/user.interface';
import { ShopsInterface } from '../interfaces/shops.interface';
import {
  GetAllProductsInterface,
  ProductsInterface,
} from '../interfaces/products.interface';
import { ORDER_OF_CATEGORY } from '../const/order-of-categories.const';
import { CheckOutBillsInterface } from '../interfaces/checkout-bills.interface';
import { GetAllOrdersInterface } from '../interfaces/orders.interface';
import { cloneDeep } from 'lodash';
import { BillsInterface } from '../interfaces/bills.interface';
import { StocksInterface } from '../interfaces/stocks.interface';
import { ORDER_OF_SUBCATEGORY } from '../const/order-of-subCategories.const';

export function getUserInfo(
  users: GetUserInterface[],
  userEmail: string | undefined
) {
  let userInfo: GetUserInterface | null = null;

  users.every((user) => {
    if (user.email === userEmail) {
      userInfo = user;
      return false;
    }

    return true;
  });

  if (users && userInfo) {
    localforage.setItem<GetUserInterface>('userInfo', userInfo);
    localforage.setItem<GetUserInterface[]>('users', users);
  }

  return userInfo;
}

export function getAllShops(shops: ShopsInterface[]) {
  localforage.setItem('shops', shops);
  return shops;
}

export function sortProductsArray(
  allItems: GetAllProductsInterface[],
  selectedShop: ShopsInterface | null
) {
  const categoryOrder = ORDER_OF_CATEGORY;
  const subCategoryOrder = ORDER_OF_SUBCATEGORY;

  // Create a map for quick lookup of category order
  const categoryIndexMap = new Map<string, number>();
  categoryOrder.forEach((category, index) => {
    categoryIndexMap.set(category, index);
  });

  // Create a map for quick lookup of subcategory order
  const subCategoryIndexMap = new Map<string, Map<string, number>>();
  subCategoryOrder.forEach(({ categoryName, order }) => {
    const subCatOrderMap = new Map<string, number>();
    order.forEach((subCat, index) => subCatOrderMap.set(subCat, index));
    subCategoryIndexMap.set(categoryName, subCatOrderMap);
  });

  // Clone and sort the main items
  const sortedItems = allItems
    .map((item) => {
      const newItem = { ...item };

      // Sort subcategories within each item
      newItem.subCategory = (item.subCategory || [])
        .map((subCat) => {
          const newSubCat = { ...subCat };
          if (newSubCat.products) {
            // Sort products within subcategory
            newSubCat.products = [...newSubCat.products].sort((a, b) => {
              // Sort products if needed, e.g., by itemName
              return (a.itemName ?? '').localeCompare(b.itemName ?? '');
            });
          }
          return newSubCat;
        })
        .sort((a, b) => {
          const subCatOrderMap =
            subCategoryIndexMap.get(item.categoryName ?? '') ||
            new Map<string, number>();
          const indexA =
            subCatOrderMap.get(a.categoryName ?? '') ?? Number.MAX_SAFE_INTEGER;
          const indexB =
            subCatOrderMap.get(b.categoryName ?? '') ?? Number.MAX_SAFE_INTEGER;
          return indexA - indexB;
        });

      return newItem;
    })
    .sort((a, b) => {
      const indexA =
        categoryIndexMap.get(a.categoryName) ?? Number.MAX_SAFE_INTEGER;
      const indexB =
        categoryIndexMap.get(b.categoryName) ?? Number.MAX_SAFE_INTEGER;
      return indexA - indexB;
    });

  let updatedItem = null;

  if (selectedShop?._id) {
    updatedItem = updateProductPrices([...sortedItems], selectedShop._id);
  }

  return updatedItem ? updatedItem : sortedItems;
}

export function getInitialSelectedCategory(
  allItems: GetAllProductsInterface[]
) {
  const category = allItems?.find(
    (category) => category?.categoryName === 'SWEETS'
  );

  const subCategory = category?.subCategory?.find(
    (subCategory) => subCategory?.categoryName === 'CHENNA'
  );

  return {
    categoryId: category?._id ? category?._id : '',
    hasSubcategories: category?.products[0]?._id
      ? false
      : category?.subCategory[0]?.products &&
        category?.subCategory[0]?.products[0]?._id
      ? true
      : false,
    subCategoryId: subCategory?._id
      ? subCategory?._id
      : category?.subCategory[0]?._id
      ? category?.subCategory[0]?._id
      : '',
  };
}

export function constructCheckoutBill(
  checkoutBill: CheckOutProductsInterface[],
  newItem: CheckOutProductsInterface
) {
  const newBill = [...checkoutBill];

  if (!checkoutBill.some((order) => order?.item?._id === newItem?.item?._id)) {
    newBill.push(newItem);
  }

  return newBill;
}

export function incrementOrDecrementCheckoutBill(
  checkoutBill: CheckOutProductsInterface[],
  itemInfo: {
    itemId: string;
    quantitySelected: string;
    quantityUnit?: 'kg' | 'piece' | 'gram' | 'amount';
  }
): CheckOutProductsInterface[] {
  const newCheckoutBill = checkoutBill.map((item) => ({ ...item }));

  const index = newCheckoutBill.findIndex(
    (item) => item?.item?._id === itemInfo.itemId
  );

  if (index !== -1) {
    newCheckoutBill[index] = {
      ...newCheckoutBill[index],
      quantitySelected: itemInfo.quantitySelected,
      item: {
        ...newCheckoutBill[index].item,
        quantityUnit:
          itemInfo.quantityUnit !== undefined
            ? itemInfo.quantityUnit
            : newCheckoutBill[index].item.quantityUnit,
      },
    };
  }

  return newCheckoutBill;
}

export function deleteProductFromCheckoutBill(
  checkoutBill: CheckOutProductsInterface[],
  deletedItem: CheckOutProductsInterface
) {
  const newBill = checkoutBill?.filter(
    (item) => item?.item?._id !== deletedItem?.item?._id
  );

  return newBill;
}

export function holdCheckoutBill(
  checkoutBill: CheckOutProductsInterface[],
  holdOrder: CheckOutBillsInterface[]
) {
  const newHoldOrder = [...holdOrder];
  newHoldOrder.push({ bill: checkoutBill });

  return newHoldOrder;
}

export function modifyHoldOrder(
  holdOrder: CheckOutBillsInterface[],
  index: number
) {
  const newHoldOrder = [...holdOrder];
  newHoldOrder?.splice(index, 1);

  return newHoldOrder;
}

export function returnOrdersOnDateChange(
  orders: GetAllOrdersInterface[] | null
) {
  const newOrders = cloneDeep(orders);

  return newOrders;
}

export function returnBillsOnDateChange(bills: BillsInterface[]) {
  const newBills = cloneDeep(bills);

  return newBills;
}

export function updateStock(
  allStocks: StocksInterface[] | null,
  payload: { _id: string; itemQuantity: string }
) {
  const modifiedStocks: StocksInterface[] = [];

  if (allStocks) {
    allStocks?.forEach((stock) => {
      let newQuantity = null;

      if (stock?._id === payload?._id) {
        newQuantity = parseFloat(payload?.itemQuantity);
      }

      if (newQuantity) {
        modifiedStocks?.push({ ...stock, itemQuantity: newQuantity });
      } else {
        modifiedStocks?.push(stock);
      }
    });
  }

  return modifiedStocks ? modifiedStocks : allStocks;
}

// Function to update product prices in the data object
function updateProductPrices(
  data: GetAllProductsInterface[],
  currentShopId: string
) {
  return data.map((category) => ({
    ...category,
    products: updatePrices(category.products, currentShopId) || [],
    subCategory: category.subCategory.map((subCat) => ({
      ...subCat,
      products: updatePrices(subCat.products, currentShopId) || [],
    })),
  }));
}

function updatePrices(
  products: ProductsInterface[] | null,
  currentShopId: string
) {
  return products?.map((product) => {
    if (product.otherShopPrices) {
      const matchedPrice = product.otherShopPrices.find(
        (priceEntry) => priceEntry.shopId === currentShopId
      );

      if (product?.itemPrice?.perPieceWeight && matchedPrice?.itemPrice) {
        matchedPrice.itemPrice = {
          ...matchedPrice?.itemPrice,
          perPieceWeight: product?.itemPrice?.perPieceWeight,
        };
      }

      if (matchedPrice) {
        return {
          ...product,
          itemPrice: { ...matchedPrice.itemPrice },
        };
      }
    }
    return product;
  });
}
