import { Injectable } from '@angular/core';
import { InvoiceInterface } from 'src/app/interfaces/invoice.interface';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private currentDate: string;

  constructor() {
    // Set the current date in the format you need
    this.currentDate = new Date().toLocaleDateString();
  }

  // Method to generate the invoice HTML from the provided invoice details
  generateInvoiceHtml(invoice: InvoiceInterface | null): string {
    if (!invoice) return '';

    return `
      <div id="invoice" class="invoice-container">
        <div class="invoice-header">
          <h1>${invoice.shopName} RESTAURANT</h1>
          <h2>${invoice.shopAddress}<h2>
          <p class="invoice-date">${this.currentDate}</p>
        </div>
        <div class="invoice-details">
          <p><strong>Order ID:</strong> ${invoice.orderNumber}</p>
        </div>
        <div class="invoice-items">
          ${invoice.items
            .map(
              (item) => `
            <div class="invoice-item">
              <div class="item-description">${item.itemName}</div>
              <div class="item-quantity-price">
                <span>${item.quantity} ${item.quantityUnit}</span>
                <span>${item.subTotal}</span>
              </div>
            </div>
          `
            )
            .join('')}
        </div>
        <div class="invoice-summary">
          <p><strong>Total:</strong></p>
          <p>Rs ${invoice.totalAmount}</p>
        </div>
        <div class="invoice-footer">
          <p>Thank you, visit us again!</p>
        </div>
      </div>
    `;
  }

  // Method to trigger the print functionality
  printInvoice(invoice: InvoiceInterface | null): void {
    const invoiceHtml = this.generateInvoiceHtml(invoice);

    // Define print styles
    const printStyles = `
      <style>
        body {
          font-family: Arial, sans-serif;
          font-size: 12px;
          width: 100%;
          margin: 0;
          padding: 0;
        }
        .invoice-container {
          text-align: center;
          min-height: 50px; /* Ensures that there is at least some space for small bills */
        }
        .invoice-header h1 {
          font-size: 16px;
          margin: 0;
        }
        .invoice-header h2 {
          font-size: 14px;
          margin: 0;
        }
        .invoice-date {
          font-size: 10px;
          margin: 5px 0 0 0;
        }
        .invoice-details {
          margin: 10px 0;
        }
        .invoice-details p {
          margin: 5px 0;
          font-size: 12px;
        }
        .invoice-items {
          border-bottom: 1px solid #000;
          padding-bottom: 10px;
          margin-bottom: 10px;
          text-align: left;
        }
        .invoice-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
          padding: 5px 0;
        }
        .item-description {
          flex: 1;
          flex-wrap: wrap;
          text-align: left;
          margin-right: 5px;
        }
        .item-quantity-price {
          flex: 1;
          display: flex;
          justify-content: space-between;
          gap: 5px;
        }
        .item-quantity-price span {
          font-size: 12px;
        }
        .invoice-summary {
          margin-top: 10px;
          border-top: 1px solid #000;
          padding-top: 10px;
          display: flex;
          justify-content: space-between;
        }
        .invoice-summary p {
          margin: 5px 0;
          font-size: 12px;
          font-weight: bold;
        }
        .invoice-footer {
          margin-top: 10px;
          padding-top: 10px;
          border-top: 1px solid #000;
        }
        .invoice-footer p {
          margin: 0;
          font-size: 10px;
        }
        @media print {
          .print-btn {
            display: none;
          }
        }
      </style>
    `;

    // Create an iframe for printing
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.border = 'none';
    document.body.appendChild(iframe);

    // Write the content to the iframe
    const doc = iframe.contentWindow?.document;
    if (doc) {
      doc.open();
      doc.write(`
        <html>
          <head>
            <title>Print Invoice</title>
            ${printStyles}
          </head>
          <body>
            ${invoiceHtml}
          </body>
        </html>
      `);
      doc.close();

      // Trigger the print dialog
      iframe.contentWindow?.focus();
      iframe.contentWindow?.print();

      // Clean up by removing the iframe
      document.body.removeChild(iframe);
    }
  }
}
