import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState } from '../interfaces/app.state';

export const selectApp = createFeatureSelector<AppState>('app');

export const selectLeftNavState = createSelector(
  selectApp,
  (state) => state.leftNavState
);

export const selectSearchBarValue = createSelector(
  selectApp,
  (state) => state.searchBarValue
);

export const selectCheckoutBill = createSelector(
  selectApp,
  (state) => state.checkoutBill
);

export const selectHoldOrder = createSelector(
  selectApp,
  (state) => state.holdOrders
);

export const selectUserInfo = createSelector(
  selectApp,
  (state) => state.userInfo
);

export const selectAllUsers = createSelector(
  selectApp,
  (state) => state.allUsers
);

export const selectAllProducts = createSelector(
  selectApp,
  (state) => state.allProducts
);

export const selectSelectedShop = createSelector(
  selectApp,
  (state) => state.selectedShop
);

export const selectAllStocks = createSelector(
  selectApp,
  (state) => state.allStocks
);

export const selectSelectedCategory = createSelector(
  selectApp,
  (state) => state.selectedCategory
);

export const selectSelectedSubCategory = createSelector(
  selectApp,
  (state) => state.selectedSubCategory
);

export const selectAllOrders = createSelector(
  selectApp,
  (state) => state.orders
);

export const selectAllShops = createSelector(selectApp, (state) => state.shops);

export const selectAllSentStock = createSelector(
  selectApp,
  (state) => state.allSentStock
);

export const selectUploadedFileURL = createSelector(
  selectApp,
  (state) => state.fileUploadUrl
);
export const selectUploadProgress = createSelector(
  selectApp,
  (state) => state.fileUploadProgress
);

export const selectAllBills = createSelector(selectApp, (state) => state.bills);
