<div class="leftNavContainer">
  <ul>
    <!-- <li><a routerLink="/admin" routerLinkActive="active">ADMIN</a></li> -->
    <li><a routerLink="/dashboard" routerLinkActive="active">HOME</a></li>
    <li>
      <a [routerLink]="['/orders']" routerLinkActive="active">ORDERS</a>
    </li>
    <li>
      <a [routerLink]="['/stocks']" routerLinkActive="active">STOCKS</a>
    </li>
    <li>
      <a [routerLink]="['/products']" routerLinkActive="active">PRODUCTS</a>
    </li>
    <li>
      <a [routerLink]="['/bills']" routerLinkActive="active">BILLS</a>
    </li>
    @if ((selectedUserRole$ | async) === 'admin') {
    <li>
      <a [routerLink]="['/analytics']" routerLinkActive="active">ANALYTICS</a>
    </li>
    }
  </ul>
</div>
