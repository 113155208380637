import { createAction, props } from '@ngrx/store';
import { CheckOutProductsInterface } from '../interfaces/checkout-products.interface';
import { GetUserInterface } from '../interfaces/user.interface';
import { ShopsInterface } from '../interfaces/shops.interface';
import {
  GetAllProductsInterface,
  ProductsInterface,
} from '../interfaces/products.interface';
import { CategoriesInterface } from '../interfaces/categories.interface';
import {
  GetAllSentStocksInterface,
  ReceiveStockInterface,
  StocksInterface,
} from '../interfaces/stocks.interface';
import {
  GetAllOrdersInterface,
  PlaceOrderInterface,
} from '../interfaces/orders.interface';
import { BillsInterface } from '../interfaces/bills.interface';
import { InvoiceInterface } from '../interfaces/invoice.interface';

export const changeLeftNavState = createAction('[Left Nav] Change');

export const changeSelectedCategory = createAction(
  '[Category] Change selected category',
  props<{
    categoryId: string;
    hasSubcategories: boolean;
    selectedSubCategoryId?: string;
  }>()
);

export const changeSelectedSubCategory = createAction(
  '[Category] Change selected sub category',
  props<{ categoryId: string }>()
);

export const searchBarValue = createAction(
  '[Search Bar] Products',
  props<{ searchBarValue: ProductsInterface[] }>()
);

export const addCheckoutBillItem = createAction(
  '[Bill] Add Checkout Item',
  props<{ order: CheckOutProductsInterface }>()
);

export const deleteCheckoutBillItem = createAction(
  '[Bill] Delete Checkout Item',
  props<{ item: CheckOutProductsInterface }>()
);

export const modifyCheckoutBill = createAction(
  '[Bill] Modify Checkout Bill',
  props<{
    itemId: string;
    quantitySelected: string;
    quantityUnit?: 'kg' | 'piece' | 'gram' | 'amount';
  }>()
);

export const holdOrder = createAction('[Bill] Hold Order');

export const switchOrder = createAction(
  '[Bill] Switch Order',
  props<{ index: number }>()
);

export const orderDateChanged = createAction('[Order] Date changed');

// -------------------------------Users-------------------------------

export const signOut = createAction('[Users] Sign Out');
export const signOutSuccess = createAction('[Users] Sign Out Success');
export const signOutFail = createAction(
  '[Users] Sign Out Fail',
  props<{ message: unknown }>()
);

export const getAllUsers = createAction(
  '[Users] Get All Users',
  props<{ userEmail: string | undefined }>()
);
export const getAllUsersSuccess = createAction(
  '[Users] Get All Users Success',
  props<{ users: GetUserInterface[]; userEmail: string | undefined }>()
);
export const getAllUsersFail = createAction(
  '[Users] Get All Users Fail',
  props<{ message: unknown }>()
);

export const createUser = createAction(
  '[Users] Create User',
  props<{ payload: GetUserInterface }>()
);
export const createUserSuccess = createAction(
  '[Users] Create User Success',
  props<{ user: GetUserInterface }>()
);
export const createUserFail = createAction(
  '[Users] Create User Fail',
  props<{ message: unknown }>()
);

export const updateUser = createAction(
  '[Users] Update User',
  props<{ id: number; payload: GetUserInterface }>()
);
export const updateUserSuccess = createAction(
  '[Users] Update User Success',
  props<{ user: GetUserInterface }>()
);
export const updateUserFail = createAction(
  '[Users] Update User Fail',
  props<{ message: unknown }>()
);

export const deleteUser = createAction(
  '[Users] Delete User',
  props<{ id: number }>()
);
export const deleteUserSuccess = createAction(
  '[Users] Delete User Success',
  props<{ user: GetUserInterface }>()
);
export const deleteUserFail = createAction(
  '[Users] Delete User Fail',
  props<{ message: unknown }>()
);
// -------------------------------------------------------------------

// -------------------------------SHOPS-------------------------------
export const getAllShops = createAction('[Shops] Get All Shops');
export const getAllShopsSuccess = createAction(
  '[Shops] Get All Shops Success',
  props<{ shops: ShopsInterface[] }>()
);
export const getAllShopsFail = createAction(
  '[Shops] Get All Shops Fail',
  props<{ message: unknown }>()
);
// -------------------------------------------------------------------

// -----------------------------PRODUCTS-----------------------------
export const getAllProducts = createAction('[Products] Get All Products');
export const getAllProductsSuccess = createAction(
  '[Products] Get All Products Success',
  props<{ products: GetAllProductsInterface[] }>()
);
export const getAllProductsFail = createAction(
  '[Products] Get All Products Fail',
  props<{ message: unknown }>()
);
// ------------------------------------------------------------------

// ----------------------------CATEGORIES----------------------------
export const createCategory = createAction(
  '[Category] Create Category',
  props<{ payload: CategoriesInterface }>()
);
export const createCategorySuccess = createAction(
  '[Category] Create Category Success',
  props<{ categories: CategoriesInterface }>()
);
export const createCategoryFail = createAction(
  '[Category] Create Category Fail',
  props<{ message: unknown }>()
);

export const updateCategory = createAction(
  '[Category] Update Category',
  props<{ payload: CategoriesInterface }>()
);
export const updateCategorySuccess = createAction(
  '[Category] Update Category Success',
  props<{ categories: CategoriesInterface }>()
);
export const updateCategoryFail = createAction(
  '[Category] Update Category Fail',
  props<{ message: unknown }>()
);

export const deleteCategory = createAction(
  '[Category] Delete Category',
  props<{ id: string }>()
);
export const deleteCategorySuccess = createAction(
  '[Category] Delete Category Success',
  props<{ message: string }>()
);
export const deleteCategoryFail = createAction(
  '[Category] Delete Category Fail',
  props<{ message: unknown }>()
);
// ------------------------------------------------------------------

// -----------------------------PRODUCTS-----------------------------
export const createProduct = createAction(
  '[Products] Create Product',
  props<{ payload: ProductsInterface }>()
);
export const createProductSuccess = createAction(
  '[Products] Create Product Success',
  props<{ response: ProductsInterface }>()
);
export const createProductFail = createAction(
  '[Products] Create Product Fail',
  props<{ message: unknown }>()
);

export const updateProduct = createAction(
  '[Products] Update Product',
  props<{ payload: ProductsInterface }>()
);
export const updateProductSuccess = createAction(
  '[Products] Update Product Success',
  props<{ response: ProductsInterface }>()
);
export const updateProductFail = createAction(
  '[Products] Update Product Fail',
  props<{ message: unknown }>()
);

export const deleteProduct = createAction(
  '[Products] Delete Product',
  props<{ id: string; imageLink?: string | null }>()
);
export const deleteProductSuccess = createAction(
  '[Products] Delete Product Success',
  props<{ message: string }>()
);
export const deleteProductFail = createAction(
  '[Products] Delete Product Fail',
  props<{ message: unknown }>()
);
// ------------------------------------------------------------------

// ------------------------------STOCKS------------------------------
export const getAllStocks = createAction(
  '[Stocks] Get All Stocks',
  props<{ id: string }>()
);
export const getAllStocksSuccess = createAction(
  '[Stocks] Get All Stocks Success',
  props<{ response: StocksInterface[] }>()
);
export const getAllStocksFail = createAction(
  '[Stocks] Get All Stocks Fail',
  props<{ message: unknown }>()
);

export const addStock = createAction(
  '[Stocks] Add Stocks',
  props<{
    comment?: string;
  }>()
);
export const addStockSuccess = createAction(
  '[Stocks] Add Stocks Success',
  props<{ response: unknown }>()
);
export const addStockFail = createAction(
  '[Stocks] Add Stocks Fail',
  props<{ message: unknown }>()
);

export const sendStock = createAction(
  '[Stocks] Send Stocks',
  props<{
    containerNo: string;
    receiverShopName: string;
    itemDescription?: string;
    comment?: string;
  }>()
);
export const sendStockSuccess = createAction(
  '[Stocks] Send Stocks Success',
  props<{ response: unknown }>()
);
export const sendStockFail = createAction(
  '[Stocks] Send Stocks Fail',
  props<{ message: unknown }>()
);

export const receiveStock = createAction(
  '[Stocks] Receive Stocks',
  props<ReceiveStockInterface>()
);
export const receiveStockSuccess = createAction(
  '[Stocks] Receive Stocks Success',
  props<{ response: unknown; receiverId?: string }>()
);
export const receiveStockFail = createAction(
  '[Stocks] Receive Stocks Fail',
  props<{ message: unknown }>()
);

export const getAllSentStock = createAction(
  '[Stocks] Get All Sent Stock',
  props<{ receiverShopId?: string }>()
);
export const getAllSentStockSuccess = createAction(
  '[Stocks] Get All Sent Stock Success',
  props<{ response: GetAllSentStocksInterface[] }>()
);
export const getAllSentStockFail = createAction(
  '[Stocks] Get All Sent Stock Fail',
  props<{ message: unknown }>()
);

export const updateStock = createAction(
  '[Stocks] Update Stocks',
  props<{
    _id: string;
    itemQuantity: string;
  }>()
);
export const updateStockSuccess = createAction(
  '[Stocks] Update Stocks Success',
  props<{ response: { _id: string; itemQuantity: string } }>()
);
export const updateStockFail = createAction(
  '[Stocks] Update Stocks Fail',
  props<{ message: unknown }>()
);

export const deleteStock = createAction(
  '[Stocks] Delete Stock By Id',
  props<{ id: string }>()
);
export const deleteStockSuccess = createAction(
  '[Stocks] Delete Stock By Id Success',
  props<{ id: string }>()
);
export const deleteStockFail = createAction(
  '[Stocks] Delete Stock By Id Fail',
  props<{ message: unknown }>()
);
// ------------------------------------------------------------------

// ------------------------------ORDERS------------------------------
export const getAllOrders = createAction('[Orders] Get All Orders');
export const getAllOrdersSuccess = createAction(
  '[Orders] Get All Orders Success',
  props<{ orders: GetAllOrdersInterface[] }>()
);
export const getAllOrdersrFail = createAction(
  '[Orders] Get All Orders Fail',
  props<{ message: unknown }>()
);

export const getAllOrdersByUserId = createAction(
  '[Orders] Get All Orders By User Id',
  props<{ id: string }>()
);
export const getAllOrdersByUserIdSuccess = createAction(
  '[Orders] Get All Orders By User Id Success',
  props<{ orders: GetAllOrdersInterface[] }>()
);
export const getAllOrdersrByUserIdFail = createAction(
  '[Orders] Get All Orders By User Id Fail',
  props<{ message: unknown }>()
);

export const placeOrder = createAction(
  '[Orders] Place Order',
  props<{ payload: PlaceOrderInterface }>()
);
export const placeOrderSuccess = createAction(
  '[Orders] Place Order Success',
  props<{ response: unknown }>()
);
export const placeOrderFail = createAction(
  '[Orders] Place Order Fail',
  props<{ message: unknown }>()
);

export const updateOrder = createAction(
  '[Orders] Update Order',
  props<{ payload: GetAllOrdersInterface }>()
);

export const updateOrderSuccess = createAction(
  '[Orders] Update Order Success',
  props<{ response: { data: GetAllOrdersInterface; userRole: string } }>()
);
export const updateOrderFail = createAction(
  '[Orders] Update Order Fail',
  props<{ message: unknown }>()
);
// ------------------------------------------------------------------

// -----------------------------FILE UPLOAD-----------------------------
export const uploadFile = createAction(
  '[Files] Upload File',
  props<{
    file: File;
    productType: 'create' | 'update';
    payload: ProductsInterface;
  }>()
);
export const uploadFileSuccess = createAction(
  '[Files] Upload File Success',
  props<{ url: string }>()
);
export const uploadFileFail = createAction(
  '[Files] Upload File Fail',
  props<{ message: unknown }>()
);

export const deleteFile = createAction(
  '[Files] Delete File',
  props<{
    key: string;
    file?: File;
    productType?: 'create' | 'update';
    payload?: ProductsInterface;
  }>()
);
export const deleteFileSuccess = createAction(
  '[Files] Delete File Success',
  props<{ response: unknown }>()
);
export const deleteFileFail = createAction(
  '[Files] Delete File Fail',
  props<{ message: unknown }>()
);

// -----------------------------Bills-----------------------------
export const getAllBills = createAction('[Bills] Get All Bills');
export const getAllBillsSuccess = createAction(
  '[Bills] Get All Bills Success',
  props<{ bills: BillsInterface[] }>()
);
export const getAllBillsFail = createAction(
  '[Bills] Get All Bills Fail',
  props<{ message: unknown }>()
);

export const createBill = createAction(
  '[Bills] Create Bill',
  props<{ payload: BillsInterface }>()
);
export const createBillSuccess = createAction(
  '[Bills] Create Bill Success',
  props<{ response: BillsInterface }>()
);
export const createBillFail = createAction(
  '[Bills] Create Bill Fail',
  props<{ message: unknown }>()
);

export const updateBill = createAction(
  '[Bills] Update Bill',
  props<{ payload: BillsInterface }>()
);
export const updateBillSuccess = createAction(
  '[Bills] Update Bill Success',
  props<{ response: BillsInterface }>()
);
export const updateBillFail = createAction(
  '[Bills] Update Bill Fail',
  props<{ message: unknown }>()
);

export const deleteBill = createAction(
  '[Bills] Delete Bill',
  props<{ id: string }>()
);
export const deleteBillSuccess = createAction(
  '[Bills] Delete Bill Success',
  props<{ message: string }>()
);
export const deleteBillFail = createAction(
  '[Bills] Delete Bill Fail',
  props<{ message: unknown }>()
);

export const billDateChanged = createAction('[Bill] Date changed');
//----------------------------------------------------------------------

//--------------------------------INVOICE--------------------------------
export const loadInvoice = createAction(
  '[Invoice] Load Invoice',
  props<{
    checkOutBill?: CheckOutProductsInterface[];
    invoice?: InvoiceInterface;
  }>()
);

export const loadInvoiceFail = createAction(
  '[Invoice] Load Invoice Failure',
  props<{ message: any }>()
);
//-----------------------------------------------------------------------
