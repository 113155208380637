import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  CreateUserInterface,
  GetUserInterface,
} from '../interfaces/user.interface';
import { catchError } from 'rxjs/internal/operators/catchError';
import { USER_API_ENDPOINTS } from '../api/user-api-endpoints';
import { throwError } from 'rxjs/internal/observable/throwError';
import { environment } from 'src/environments/environment';
import { ShopsInterface } from '../interfaces/shops.interface';
import {
  GetAllProductsInterface,
  ProductsInterface,
} from '../interfaces/products.interface';
import { CategoriesInterface } from '../interfaces/categories.interface';
import { NgToastService } from 'ng-angular-popup';
import {
  AddStocksInterface,
  GetAllSentStocksInterface,
  ReceiveStockInterface,
  SendStocksInterface,
  StocksInterface,
} from '../interfaces/stocks.interface';
import {
  GetAllOrdersInterface,
  OrderInterface,
} from '../interfaces/orders.interface';
import * as localforage from 'localforage';
import { OKTA_AUTH } from '@okta/okta-angular';
import { Router } from '@angular/router';
import { from } from 'rxjs/internal/observable/from';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { BillsInterface } from '../interfaces/bills.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private http = inject(HttpClient);
  private toast = inject(NgToastService);
  private apiUrl = environment.apiUrl;
  private oktaAuth = inject(OKTA_AUTH);
  private router = inject(Router);

  isLoading = signal(false);

  private handleError(error: any): Observable<never> {
    console.error('API Error:', error);
    return throwError(() => error);
  }

  alertMessage(message: string, type: string) {
    if (type === 'success') {
      this.toast.success(message);
      return;
    }

    if (type === 'error') {
      this.toast.danger(message);
      return;
    }
  }

  signOut(): Observable<void> {
    return from(
      (async () => {
        try {
          this.isLoading.set(true);
          await localforage.clear();
          console.log('IndexedDB cleared successfully.');

          await this.removeBackendCache();
          console.log('Backend cache removed successfully');

          localStorage.clear();
          console.log('Local Storage cleared successfully.');

          await this.oktaAuth.signOut();
          console.log('Signed out successfully.');

          this.oktaAuth.authStateManager.unsubscribe();
          this.isLoading.set(false);
        } catch (error) {
          console.log('Sign Out Error', error);
          this.router.navigate(['/']);
          this.isLoading.set(false);
          throw error;
        }
      })()
    );
  }

  private async removeBackendCache(): Promise<void> {
    try {
      const response = await this.http
        .post<void>(this.apiUrl + USER_API_ENDPOINTS.logout, null)
        .pipe(catchError(this.handleError));

      await lastValueFrom(response);
    } catch (error) {
      console.error('Failed to remove backend cache:', error);
      throw error;
    }
  }

  getAllUsers(): Observable<GetUserInterface[]> {
    return this.http
      .get<GetUserInterface[]>(this.apiUrl + USER_API_ENDPOINTS.getAllUsers)
      .pipe(catchError(this.handleError));
  }

  createUser(payload: CreateUserInterface): Observable<GetUserInterface> {
    return this.http
      .post<GetUserInterface>(
        this.apiUrl + USER_API_ENDPOINTS.createUser,
        payload
      )
      .pipe(catchError(this.handleError));
  }

  updateUser(
    id: number,
    payload: CreateUserInterface
  ): Observable<GetUserInterface> {
    return this.http
      .put<GetUserInterface>(
        (this.apiUrl + USER_API_ENDPOINTS.deleteUser)?.replace(
          ':userId',
          id.toString()
        ),
        payload
      )
      .pipe(catchError(this.handleError));
  }

  deleteUser(id: number): Observable<any> {
    return this.http
      .delete<any>(
        (this.apiUrl + USER_API_ENDPOINTS.deleteUser)?.replace(
          ':userId',
          id.toString()
        )
      )
      .pipe(catchError(this.handleError));
  }

  getAllShops(): Observable<ShopsInterface[]> {
    return this.http
      .get<ShopsInterface[]>(this.apiUrl + USER_API_ENDPOINTS.getAllShops)
      .pipe(catchError(this.handleError));
  }

  getAllProducts(): Observable<GetAllProductsInterface[]> {
    return this.http
      .get<GetAllProductsInterface[]>(
        this.apiUrl + USER_API_ENDPOINTS.getAllProducts
      )
      .pipe(catchError(this.handleError));
  }

  createCategory(
    payload: CategoriesInterface
  ): Observable<CategoriesInterface> {
    return this.http
      .post<CategoriesInterface>(
        this.apiUrl + USER_API_ENDPOINTS.createCategory,
        payload
      )
      .pipe(catchError(this.handleError));
  }

  updateCategory(
    payload: CategoriesInterface
  ): Observable<CategoriesInterface> {
    return this.http
      .put<CategoriesInterface>(
        this.apiUrl +
          USER_API_ENDPOINTS.updateCategory?.replace(':id', payload._id),
        payload
      )
      .pipe(catchError(this.handleError));
  }

  deleteCategory(id: string): Observable<{ message: string }> {
    return this.http
      .delete<{ message: string }>(
        (this.apiUrl + USER_API_ENDPOINTS.deleteCategory)?.replace(
          ':id',
          id.toString()
        )
      )
      .pipe(catchError(this.handleError));
  }

  createProduct(payload: ProductsInterface): Observable<ProductsInterface> {
    return this.http
      .post<ProductsInterface>(
        this.apiUrl + USER_API_ENDPOINTS.createProduct,
        payload
      )
      .pipe(catchError(this.handleError));
  }

  updateProduct(payload: ProductsInterface): Observable<ProductsInterface> {
    return this.http
      .put<ProductsInterface>(
        this.apiUrl +
          USER_API_ENDPOINTS.updateProduct?.replace(':id', payload._id),
        payload
      )
      .pipe(catchError(this.handleError));
  }

  deleteProduct(id: string): Observable<{ message: string }> {
    return this.http
      .delete<{ message: string }>(
        (this.apiUrl + USER_API_ENDPOINTS.deleteProduct)?.replace(
          ':id',
          id.toString()
        )
      )
      .pipe(catchError(this.handleError));
  }

  getAllStocksByShopId(
    id: string,
    shops?: ShopsInterface[],
    userInfo?: GetUserInterface | null
  ): Observable<StocksInterface[]> {
    let apiEndPoint = USER_API_ENDPOINTS.getAllStocksByShopId;

    if (userInfo?.role === 'admin') {
      apiEndPoint = USER_API_ENDPOINTS.getAllStocks;
    }

    return this.http
      .get<StocksInterface[]>(
        (this.apiUrl + apiEndPoint)?.replace(':id', id.toString())
      )
      .pipe(catchError(this.handleError));
  }

  getAllOrders(): Observable<GetAllOrdersInterface[]> {
    return this.http
      .get<GetAllOrdersInterface[]>(
        this.apiUrl + USER_API_ENDPOINTS.getAllOrders
      )
      .pipe(catchError(this.handleError));
  }

  getAllOrdersByUserId(id: string): Observable<GetAllOrdersInterface[]> {
    return this.http
      .get<GetAllOrdersInterface[]>(
        this.apiUrl +
          USER_API_ENDPOINTS.getAllOrdersByUserId?.replace(':id', id)
      )
      .pipe(catchError(this.handleError));
  }

  updateOrder(
    payload: GetAllOrdersInterface
  ): Observable<GetAllOrdersInterface> {
    return this.http
      .put<GetAllOrdersInterface>(
        this.apiUrl +
          USER_API_ENDPOINTS.updateOrder?.replace(':id', payload._id),
        payload
      )
      .pipe(catchError(this.handleError));
  }

  placeOrder(payload: OrderInterface): Observable<unknown> {
    return this.http
      .post<unknown>(this.apiUrl + USER_API_ENDPOINTS.placeOrder, payload)
      .pipe(catchError(this.handleError));
  }

  sendOrReceiveStock(
    payload: SendStocksInterface | ReceiveStockInterface,
    type: string
  ): Observable<unknown> {
    let url = '';
    if (type === 'SEND') {
      url = this.apiUrl + USER_API_ENDPOINTS.sendStock;
    } else {
      url = this.apiUrl + USER_API_ENDPOINTS.receivedStock;
    }
    return this.http
      .post<unknown>(url, payload)
      .pipe(catchError(this.handleError));
  }

  addStock(payload: AddStocksInterface): Observable<unknown> {
    const url = this.apiUrl + USER_API_ENDPOINTS.addStock;
    return this.http
      .post<unknown>(url, payload)
      .pipe(catchError(this.handleError));
  }

  updateStockById(payload: {
    _id: string;
    itemQuantity: string;
  }): Observable<unknown> {
    return this.http
      .put<unknown>(
        this.apiUrl +
          USER_API_ENDPOINTS.updateStockById?.replace(':id', payload._id),
        payload
      )
      .pipe(catchError(this.handleError));
  }

  getAllSentStock(shopId?: string): Observable<GetAllSentStocksInterface[]> {
    if (shopId) {
      let params = new HttpParams();
      params = params.set('receiverShopId', shopId);
      return this.http
        .get<GetAllSentStocksInterface[]>(
          this.apiUrl + USER_API_ENDPOINTS.getAllSentStock,
          { params: params }
        )
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .get<GetAllSentStocksInterface[]>(
          this.apiUrl + USER_API_ENDPOINTS.getAllSentStock
        )
        .pipe(catchError(this.handleError));
    }
  }

  deleteStock(id: string): Observable<{ message: string }> {
    return this.http
      .delete<{ message: string }>(
        (this.apiUrl + USER_API_ENDPOINTS.deleteStockById)?.replace(
          ':id',
          id.toString()
        )
      )
      .pipe(catchError(this.handleError));
  }

  uploadFile(file: File): Observable<{ fileUrl: string }> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<{ fileUrl: string }>(
        this.apiUrl + USER_API_ENDPOINTS.fileUpload,
        formData
      )
      .pipe(catchError(this.handleError));
  }

  deleteFile(key: string): Observable<unknown> {
    const modifiedKey = key.split('.com/')[1];
    const url = `${this.apiUrl}${USER_API_ENDPOINTS.deleteFile}`;
    const params = new HttpParams().set('key', modifiedKey);

    return this.http
      .delete<unknown>(url, { params })
      .pipe(catchError(this.handleError));
  }

  getAllBills(userInfo: GetUserInterface | null): Observable<BillsInterface[]> {
    if (userInfo?.role === 'admin') {
      return this.http
        .get<BillsInterface[]>(this.apiUrl + USER_API_ENDPOINTS.getAllBills)
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .get<BillsInterface[]>(
          (this.apiUrl + USER_API_ENDPOINTS.getAllBillsByShopId)?.replace(
            ':id',
            userInfo?.shopId?._id?.toString()
              ? userInfo?.shopId?._id?.toString()
              : ''
          )
        )
        .pipe(catchError(this.handleError));
    }
  }

  createBill(payload: BillsInterface): Observable<BillsInterface> {
    return this.http
      .post<BillsInterface>(
        this.apiUrl + USER_API_ENDPOINTS.createBill,
        payload
      )
      .pipe(catchError(this.handleError));
  }

  updateBill(payload: BillsInterface): Observable<BillsInterface> {
    return this.http
      .put<BillsInterface>(
        this.apiUrl +
          USER_API_ENDPOINTS.updateBill?.replace(':id', payload._id),
        payload
      )
      .pipe(catchError(this.handleError));
  }

  deleteBill(id: string): Observable<{ message: string }> {
    return this.http
      .delete<{ message: string }>(
        (this.apiUrl + USER_API_ENDPOINTS.deleteBill)?.replace(
          ':id',
          id.toString()
        )
      )
      .pipe(catchError(this.handleError));
  }
}
