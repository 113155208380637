import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { OktaAuthStateService } from '@okta/okta-angular';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { selectUserInfo } from '../ngrx/app.selectors';

export const authGuard: CanActivateFn = (route, state) => {
  const destroyRef = inject(DestroyRef);
  const router = inject(Router);
  const oktaAuthStateService = inject(OktaAuthStateService);
  const store = inject(Store);

  const isAuthenticated$: Observable<boolean | undefined> =
    oktaAuthStateService.authState$.pipe(
      map((value) => value?.isAuthenticated)
    );

  const userRole$ = store
    ?.select(selectUserInfo)
    ?.pipe(map((userInfo) => userInfo?.role));

  isAuthenticated$.pipe(takeUntilDestroyed(destroyRef))?.subscribe((value) => {
    if (!value) {
      router.navigate(['/login']);
    }
  });

  if (route?.routeConfig?.path === 'analytics') {
    userRole$.pipe(takeUntilDestroyed(destroyRef))?.subscribe((value) => {
      if (value !== 'admin') {
        router.navigate(['/dashboard']);
      }
    });
  }

  return true;
};
