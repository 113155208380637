import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
// import * as CryptoJS from 'crypto-js';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next
) => {
  const token = localStorage.getItem('accessToken');
  const oktaToken = token ? JSON.parse(token)?.accessToken : null;

  if (!oktaToken) {
    return throwError(() => new Error('Okta Token not found in interceptor'));
  }

  const authReq: HttpRequest<any> = req.clone({
    setHeaders: {
      Authorization: `${oktaToken}`,
    },
  });

  return next(authReq).pipe(
    catchError((error: any) => {
      console.error('HTTP error in interceptor:', error);

      if (error.status === 401) {
        // Handle unauthorized (e.g., redirect to login)
      }

      return throwError(() => error);
    })
  );
};
