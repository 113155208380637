import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as AppActions from './app.actions';
import { ApiService } from '../services/api.service';
import { CreateUserInterface } from '../interfaces/user.interface';
import { ShopsInterface } from '../interfaces/shops.interface';
import {
  GetAllProductsInterface,
  ProductsInterface,
} from '../interfaces/products.interface';
import { CategoriesInterface } from '../interfaces/categories.interface';
import { NgToastService } from 'ng-angular-popup';
import { Store } from '@ngrx/store';
import {
  GetAllSentStocksInterface,
  StocksInterface,
} from '../interfaces/stocks.interface';
import {
  selectAllShops,
  selectCheckoutBill,
  selectSelectedShop,
  selectUserInfo,
} from './app.selectors';
import {
  constructInvoice,
  constructAddStockPayload,
  constructOrderPayload,
  constructSendStockPayload,
  getShopId,
  constructReceiveStockPayload,
} from '../utils/effects-functions';
import { GetAllOrdersInterface } from '../interfaces/orders.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { BillsInterface } from '../interfaces/bills.interface';
import { InvoiceService } from '../services/invoice-service/invoice.service';

@Injectable()
export class appEffects {
  private actions$ = inject(Actions);
  private apiService = inject(ApiService);
  private toast = inject(NgToastService);
  private store = inject(Store);
  private invoiceService = inject(InvoiceService);

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.signOut),
      switchMap(() =>
        this.apiService.signOut().pipe(
          map(() => AppActions.signOutSuccess()),
          catchError((error) => of(AppActions.signOutFail({ message: error })))
        )
      )
    )
  );

  getAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getAllUsers),
      mergeMap((action: { userEmail: string | undefined }) =>
        this.apiService.getAllUsers().pipe(
          mergeMap((response) => [
            AppActions.getAllUsersSuccess({
              users: response,
              userEmail: action.userEmail,
            }),
            AppActions.getAllShops(),
          ]),
          catchError((error) =>
            of(AppActions.getAllUsersFail({ message: error }))
          )
        )
      )
    )
  );

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createUser),
      switchMap((action: { payload: CreateUserInterface }) =>
        this.apiService.createUser(action?.payload).pipe(
          map((response) => AppActions.createUserSuccess({ user: response })),
          catchError((error) =>
            of(AppActions.createUserFail({ message: error.message }))
          )
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.updateUser),
      switchMap((action: { id: number; payload: CreateUserInterface }) =>
        this.apiService.updateUser(action?.id, action?.payload).pipe(
          map((response) => AppActions.updateUserSuccess({ user: response })),
          catchError((error) =>
            of(AppActions.updateUserFail({ message: error.message }))
          )
        )
      )
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.deleteUser),
      switchMap((action: { id: number }) =>
        this.apiService.deleteUser(action?.id).pipe(
          map((response) => AppActions.deleteUserSuccess({ user: response })),
          catchError((error) =>
            of(AppActions.deleteUserFail({ message: error.message }))
          )
        )
      )
    )
  );

  getAllShops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getAllShops),
      withLatestFrom(this.store.select(selectUserInfo)),
      concatMap(([, userInfo]) =>
        this.apiService.getAllShops().pipe(
          mergeMap((response: ShopsInterface[]) => [
            AppActions.getAllShopsSuccess({ shops: response }),
            AppActions.getAllStocks({
              id: getShopId(response, userInfo?.shopId?._id),
            }),
            AppActions.getAllProducts(),
          ]),
          catchError((error) =>
            of(AppActions.getAllShopsFail({ message: error }))
          )
        )
      )
    )
  );

  getAllProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getAllProducts),
      mergeMap(() =>
        this.apiService.getAllProducts().pipe(
          map((response: GetAllProductsInterface[]) =>
            AppActions.getAllProductsSuccess({ products: response })
          ),
          catchError((error) =>
            of(AppActions.getAllProductsFail({ message: error }))
          )
        )
      )
    )
  );

  createCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createCategory),
      concatMap((action: { payload: CategoriesInterface }) =>
        this.apiService.createCategory(action?.payload).pipe(
          map((response: CategoriesInterface) =>
            AppActions.createCategorySuccess({ categories: response })
          ),
          catchError((error) =>
            of(AppActions.createCategoryFail({ message: error }))
          )
        )
      )
    )
  );

  updateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.updateCategory),
      switchMap((action: { payload: CategoriesInterface }) =>
        this.apiService.updateCategory(action?.payload).pipe(
          map((response: CategoriesInterface) =>
            AppActions.updateCategorySuccess({ categories: response })
          ),
          catchError((error) =>
            of(AppActions.updateCategoryFail({ message: error.message }))
          )
        )
      )
    )
  );

  deleteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.deleteCategory),
      concatMap((action: { id: string }) =>
        this.apiService.deleteCategory(action?.id).pipe(
          map((response: { message: string }) =>
            AppActions.deleteCategorySuccess(response)
          ),
          catchError((error) =>
            of(AppActions.deleteCategoryFail({ message: error }))
          )
        )
      )
    )
  );

  createProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createProduct),
      switchMap((action: { payload: ProductsInterface }) =>
        this.apiService.createProduct(action?.payload).pipe(
          map((response: ProductsInterface) =>
            AppActions.createProductSuccess({ response })
          ),
          catchError((error) =>
            of(AppActions.createProductFail({ message: error.message }))
          )
        )
      )
    )
  );

  updateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.updateProduct),
      switchMap((action: { payload: ProductsInterface }) =>
        this.apiService.updateProduct(action?.payload).pipe(
          map((response: ProductsInterface) =>
            AppActions.updateProductSuccess({ response })
          ),
          catchError((error) =>
            of(AppActions.updateProductFail({ message: error.message }))
          )
        )
      )
    )
  );

  deleteProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.deleteProduct),
      concatMap((action: { id: string; imageLink?: string | null }) =>
        this.apiService.deleteProduct(action?.id).pipe(
          mergeMap((response: { message: string }) => {
            return action?.imageLink && action?.imageLink?.length > 0
              ? [
                  AppActions.deleteProductSuccess(response),
                  AppActions.deleteFile({ key: action.imageLink }),
                ]
              : [AppActions.deleteProductSuccess(response)];
          }),
          catchError((error) =>
            of(AppActions.deleteProductFail({ message: error }))
          )
        )
      )
    )
  );

  getAllStocks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getAllStocks),
      withLatestFrom(
        this.store.select(selectAllShops),
        this.store.select(selectUserInfo)
      ),
      concatMap(([action, shops, userInfo]) =>
        this.apiService.getAllStocksByShopId(action?.id, shops, userInfo).pipe(
          map((response: StocksInterface[]) =>
            AppActions.getAllStocksSuccess({ response })
          ),
          catchError((error) =>
            of(AppActions.getAllStocksFail({ message: error }))
          )
        )
      )
    )
  );

  getAllOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getAllOrders),
      concatMap(() =>
        this.apiService.getAllOrders().pipe(
          map((response: GetAllOrdersInterface[]) =>
            AppActions.getAllOrdersSuccess({ orders: response })
          ),
          catchError((error) =>
            of(AppActions.getAllOrdersrFail({ message: error }))
          )
        )
      )
    )
  );

  getAllOrdersByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getAllOrdersByUserId),
      concatMap((action) =>
        this.apiService.getAllOrdersByUserId(action?.id).pipe(
          map((response: GetAllOrdersInterface[]) =>
            AppActions.getAllOrdersByUserIdSuccess({ orders: response })
          ),
          catchError((error) =>
            of(AppActions.getAllOrdersrByUserIdFail({ message: error }))
          )
        )
      )
    )
  );

  updateOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.updateOrder),
      withLatestFrom(this.store.select(selectUserInfo)),
      mergeMap(([action, userInfo]) =>
        this.apiService.updateOrder(action?.payload).pipe(
          map((response: GetAllOrdersInterface) =>
            AppActions.updateOrderSuccess({
              response: {
                data: response,
                userRole: userInfo?.role ? userInfo?.role : '',
              },
            })
          ),
          catchError((error) =>
            of(AppActions.updateOrderFail({ message: error.message }))
          )
        )
      )
    )
  );

  placeOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.placeOrder),
      withLatestFrom(
        this.store.select(selectCheckoutBill),
        this.store.select(selectSelectedShop),
        this.store.select(selectUserInfo)
      ),
      concatMap(([action, checkOutBill, selectedShop, userInfo]) =>
        this.apiService
          .placeOrder(
            constructOrderPayload(
              action?.payload,
              checkOutBill,
              userInfo,
              selectedShop
            )
          )
          .pipe(
            mergeMap((response: unknown) => [
              AppActions.loadInvoice({ checkOutBill }),
              AppActions.placeOrderSuccess({ response }),
            ]),
            catchError((error) =>
              of(AppActions.placeOrderFail({ message: error?.error }))
            )
          )
      )
    )
  );

  sendStock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.sendStock),
      withLatestFrom(
        this.store.select(selectCheckoutBill),
        this.store.select(selectSelectedShop),
        this.store.select(selectUserInfo),
        this.store.select(selectAllShops)
      ),
      concatMap(([action, checkOutBill, selectedShop, userInfo, allShops]) =>
        this.apiService
          .sendOrReceiveStock(
            constructSendStockPayload(
              action,
              checkOutBill,
              userInfo,
              selectedShop,
              allShops
            ),
            'SEND'
          )
          .pipe(
            map((response: unknown) =>
              AppActions.sendStockSuccess({ response })
            ),
            catchError((error) =>
              of(AppActions.sendStockFail({ message: error?.error }))
            )
          )
      )
    )
  );

  receiveStock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.receiveStock),
      withLatestFrom(this.store.select(selectCheckoutBill)),
      concatMap(([action, checkoutBill]) =>
        this.apiService.sendOrReceiveStock(constructReceiveStockPayload(action, checkoutBill), 'RECEIVE').pipe(
          map((response: unknown) =>
            AppActions.receiveStockSuccess({
              response: response,
              receiverId: action?.receiverShopId,
            })
          ),
          catchError((error) =>
            of(AppActions.receiveStockFail({ message: error?.error }))
          )
        )
      )
    )
  );

  addStock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.addStock),
      withLatestFrom(
        this.store.select(selectCheckoutBill),
        this.store.select(selectSelectedShop)
      ),
      concatMap(([action, checkOutBill, selectedShop]) =>
        this.apiService
          .addStock(constructAddStockPayload(checkOutBill, selectedShop))
          .pipe(
            mergeMap((response: unknown) => [
              AppActions.addStockSuccess({ response }),
              selectedShop?._id
                ? AppActions.getAllStocks({ id: selectedShop._id })
                : AppActions.getAllStocksFail({
                    message: 'Failed to fetch all stocks',
                  }),
            ]),
            catchError((error) =>
              of(AppActions.addStockFail({ message: error?.error }))
            )
          )
      )
    )
  );

  updateStock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.updateStock),
      concatMap((action) =>
        this.apiService
          .updateStockById({
            _id: action?._id,
            itemQuantity: action?.itemQuantity,
          })
          .pipe(
            mergeMap((response: unknown) => [
              AppActions.updateStockSuccess({ response: action }),
            ]),
            catchError((error) =>
              of(AppActions.updateStockFail({ message: error?.error }))
            )
          )
      )
    )
  );

  getAllSentStock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getAllSentStock),
      concatMap((action?: { receiverShopId?: string }) =>
        this.apiService.getAllSentStock(action?.receiverShopId).pipe(
          map((response: GetAllSentStocksInterface[]) =>
            AppActions.getAllSentStockSuccess({ response: response })
          ),
          catchError((error) =>
            of(AppActions.getAllSentStockFail({ message: error }))
          )
        )
      )
    )
  );

  deleteStock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.deleteStock),
      concatMap((action: { id: string }) =>
        this.apiService.deleteStock(action?.id).pipe(
          map(() => AppActions.deleteStockSuccess({ id: action.id })),
          catchError((error) =>
            of(AppActions.deleteStockFail({ message: error }))
          )
        )
      )
    )
  );

  // -----------------------------FILE UPLOAD-----------------------------

  uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.uploadFile),
      mergeMap(
        (action: {
          file: File;
          productType: 'create' | 'update';
          payload: ProductsInterface;
        }) =>
          this.apiService.uploadFile(action?.file).pipe(
            mergeMap((response) => {
              const newPayload = {
                ...action.payload,
                imageLink: response.fileUrl,
              };
              return [
                AppActions.uploadFileSuccess({ url: response.fileUrl }),
                action?.productType === 'create'
                  ? AppActions.createProduct({ payload: newPayload })
                  : AppActions.updateProduct({ payload: newPayload }),
              ];
            }),
            catchError((error) =>
              of(AppActions.uploadFileFail({ message: error }))
            )
          )
      )
    )
  );

  deleteFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.deleteFile),
      concatMap(
        (action: {
          key: string;
          file?: File;
          productType?: 'create' | 'update';
          payload?: ProductsInterface;
        }) =>
          this.apiService.deleteFile(action.key).pipe(
            mergeMap((response: unknown) => {
              return action?.file &&
                action?.payload &&
                action?.productType &&
                action?.productType === 'update'
                ? [
                    AppActions.deleteFileSuccess({ response }),
                    AppActions.uploadFile({
                      file: action.file,
                      payload: action.payload,
                      productType: action.productType,
                    }),
                  ]
                : [AppActions.deleteFileSuccess({ response })];
            }),
            catchError((error) =>
              of(AppActions.deleteFileFail({ message: error }))
            )
          )
      )
    )
  );

  getAllBills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getAllBills),
      withLatestFrom(this.store.select(selectUserInfo)),
      mergeMap(([action, userInfo]) =>
        this.apiService.getAllBills(userInfo).pipe(
          map((response: BillsInterface[]) =>
            AppActions.getAllBillsSuccess({ bills: response })
          ),
          catchError((error) =>
            of(AppActions.getAllBillsFail({ message: error }))
          )
        )
      )
    )
  );

  createBill$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createBill),
      withLatestFrom(
        this.store.select(selectSelectedShop),
        this.store.select(selectUserInfo)
      ),
      concatMap(([action, selectedShop, userInfo]) =>
        this.apiService
          .createBill({
            ...action?.payload,
            userId: userInfo?._id || '',
            shopId: selectedShop?._id || '',
          })
          .pipe(
            map((response: BillsInterface) =>
              AppActions.createBillSuccess({ response })
            ),
            catchError((error) =>
              of(AppActions.createBillFail({ message: error.message }))
            )
          )
      )
    )
  );

  updateBill$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.updateBill),
      switchMap((action: { payload: BillsInterface }) =>
        this.apiService.updateBill(action?.payload).pipe(
          map((response: BillsInterface) =>
            AppActions.updateBillSuccess({ response })
          ),
          catchError((error) =>
            of(AppActions.updateBillFail({ message: error.message }))
          )
        )
      )
    )
  );

  deleteBill$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.deleteBill),
      concatMap((action: { id: string }) =>
        this.apiService.deleteBill(action?.id).pipe(
          map((response: { message: string }) =>
            AppActions.deleteBillSuccess(response)
          ),
          catchError((error) =>
            of(AppActions.deleteBillFail({ message: error }))
          )
        )
      )
    )
  );
  // ---------------------------------------------------------------------------------

  // ---------------------------------INVOICE-----------------------------------------
  loadInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.loadInvoice),
      withLatestFrom(this.store.select(selectSelectedShop)),
      switchMap(([action, selectedShop]) => {
        let invoice;

        if (action?.checkOutBill) {
          invoice = constructInvoice(action?.checkOutBill, selectedShop);
        } else if (action?.invoice) {
          invoice = action?.invoice;
        }

        if (invoice) {
          this.invoiceService.printInvoice(invoice);
          return of(); // Dispatch success action
        } else {
          return of(
            AppActions.loadInvoiceFail({ message: 'Invoice not found' })
          );
        }
      }),
      catchError((error) => of(AppActions.loadInvoiceFail({ message: error })))
    )
  );
  // ---------------------------------------------------------------------------------

  // ------------------------------Toast message section------------------------------
  triggerGetAllProducts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AppActions.createCategorySuccess,
          AppActions.updateCategorySuccess,
          AppActions.deleteCategorySuccess,
          AppActions.createProductSuccess,
          AppActions.updateProductSuccess,
          AppActions.deleteProductSuccess
        ),
        tap((value) => {
          this.store.dispatch(AppActions.getAllProducts());

          switch (value?.type) {
            case AppActions.createCategorySuccess?.type: {
              this.toast.success('Category created successfully');
              break;
            }
            case AppActions.updateCategorySuccess?.type: {
              this.toast.success('Category updated successfully');
              break;
            }

            case AppActions.deleteCategorySuccess?.type: {
              this.toast.success('Category deleted successfully');
              break;
            }

            case AppActions.createProductSuccess?.type: {
              this.toast.success('Product created successfully');
              break;
            }

            case AppActions.updateProductSuccess?.type: {
              this.toast.success('Product updated successfully');
              break;
            }

            case AppActions.deleteProductSuccess?.type: {
              this.toast.success('Product deleted successfully');
              break;
            }

            default:
              break;
          }
        })
      ),
    { dispatch: false } // Specify dispatch: false to indicate that no further actions should be dispatched
  );

  triggerGetAllBills$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AppActions.createBillSuccess,
          AppActions.updateBillSuccess,
          AppActions.deleteBillSuccess
        ),
        tap((value) => {
          this.store.dispatch(AppActions.getAllBills());

          switch (value?.type) {
            case AppActions.createBillSuccess?.type: {
              this.toast.success('Bill created successfully');
              break;
            }

            case AppActions.updateBillSuccess?.type: {
              this.toast.success('Bill updated successfully');
              break;
            }

            case AppActions.deleteBillSuccess?.type: {
              this.toast.success('Bill deleted successfully');
              break;
            }

            default:
              break;
          }
        })
      ),
    { dispatch: false } // Specify dispatch: false to indicate that no further actions should be dispatched
  );

  successMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AppActions.placeOrderSuccess,
          AppActions.updateOrderSuccess,
          AppActions.sendStockSuccess,
          AppActions.addStockSuccess,
          AppActions.receiveStockSuccess,
          AppActions.updateOrderSuccess,
          // AppActions.uploadFileSuccess,
          AppActions.deleteStockSuccess,
          AppActions.updateStockSuccess
        ),
        tap((value) => {
          switch (value?.type) {
            case AppActions.placeOrderSuccess?.type: {
              this.toast.success('Order placed');
              break;
            }

            case AppActions.sendStockSuccess?.type: {
              this.store.dispatch(AppActions.getAllSentStock({}));
              this.toast.success('Stock sent successfully');
              break;
            }
            case AppActions.addStockSuccess?.type: {
              this.toast.success('Stock add successfully');
              break;
            }
            case AppActions.updateOrderSuccess?.type: {
              if (typeof value?.response?.data?.userId === 'string') {
                if (value?.response?.userRole === 'admin') {
                  this.store.dispatch(AppActions.getAllOrders());
                } else {
                  this.store.dispatch(
                    AppActions.getAllOrdersByUserId({
                      id: value?.response?.data?.userId,
                    })
                  );
                }
              }

              this.toast.success('Order updated successfully');
              break;
            }
            // case AppActions.uploadFileSuccess?.type: {
            //   this.toast.success('File uploaded successfully');
            //   break;
            // }

            case AppActions.receiveStockSuccess?.type: {
              this.store.dispatch(
                AppActions.getAllSentStock({
                  receiverShopId: value?.receiverId,
                })
              );
              this.toast.success('Stock received successfully');
              break;
            }

            case AppActions.deleteStockSuccess?.type: {
              this.toast.success('Stock deleted successfully');
              break;
            }

            case AppActions.updateStockSuccess?.type: {
              this.toast.success('Stock updated successfully');
              break;
            }

            default:
              break;
          }
        })
      ),
    { dispatch: false }
  );

  errorMessages$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AppActions.getAllUsersFail,
          AppActions.getAllProductsFail,
          AppActions.createCategoryFail,
          AppActions.deleteCategoryFail,
          AppActions.createProductFail,
          AppActions.updateProductFail,
          AppActions.deleteProductFail,
          AppActions.getAllStocksFail,
          AppActions.placeOrderFail,
          AppActions.getAllOrdersrFail,
          AppActions.updateOrderFail,
          AppActions.addStockFail,
          AppActions.sendStockFail,
          AppActions.receiveStockFail,
          AppActions.uploadFileFail,
          AppActions.deleteFileFail,
          AppActions.getAllBillsFail,
          AppActions.createBillFail,
          AppActions.updateBillFail,
          AppActions.deleteBillFail,
          AppActions.loadInvoiceFail,
          AppActions.deleteStockFail,
          AppActions.updateStockFail
        ),
        tap((value) => {
          if (value?.message) {
            const error = value.message as HttpErrorResponse;
            if (error.status === 401) {
              AppActions.signOut();
            }
          }

          switch (value?.type) {
            case AppActions.getAllUsersFail?.type: {
              this.toast.danger('Failed to fetch all users');
              break;
            }

            case AppActions.getAllProductsFail?.type: {
              this.toast.danger('Failed to fetch all products');
              break;
            }

            case AppActions.createCategoryFail?.type: {
              this.toast.danger('Failed to create category');
              break;
            }

            case AppActions.deleteCategoryFail?.type: {
              this.toast.danger('Failed to delete category');
              break;
            }

            case AppActions.createProductFail?.type: {
              this.toast.danger('Failed to create product');
              break;
            }

            case AppActions.updateProductFail?.type: {
              this.toast.danger('Failed to update product');
              break;
            }

            case AppActions.deleteProductFail?.type: {
              this.toast.danger('Failed to delete product');
              break;
            }

            case AppActions.getAllStocksFail?.type: {
              this.toast.danger('Failed to fetch all stocks');
              break;
            }

            case AppActions.placeOrderFail?.type: {
              console.log('value', value?.message);
              this.toast.danger('Could not place order, please retry !');
              break;
            }

            case AppActions.getAllOrdersrFail?.type: {
              this.toast.danger('Failed to fetch all orders');
              break;
            }

            case AppActions.updateOrderFail?.type: {
              this.toast.danger('Failed to update order');
              break;
            }
            case AppActions.addStockFail?.type: {
              this.toast.danger('Failed to add stock, please try again');
              break;
            }

            case AppActions.sendStockFail?.type: {
              this.toast.danger('Failed to send stock, please try again');
              break;
            }

            case AppActions.uploadFileFail?.type: {
              this.toast.danger('Failed to upload file');
              break;
            }

            case AppActions.deleteFileFail?.type: {
              this.toast.danger(
                'Failed to delete file, please do it from database'
              );
              break;
            }

            case AppActions.receiveStockFail?.type: {
              console.log('Error', value);
              this.toast.danger('Failed to receive stock, please try again');
              break;
            }

            case AppActions.getAllBillsFail?.type: {
              this.toast.danger('Failed to fetch all bills');
              break;
            }

            case AppActions.createBillFail?.type: {
              this.toast.danger('Failed to create bill');
              break;
            }

            case AppActions.updateBillFail?.type: {
              this.toast.danger('Failed to update bills');
              break;
            }

            case AppActions.deleteBillFail?.type: {
              this.toast.danger('Failed to delete bill');
              break;
            }

            case AppActions.loadInvoiceFail?.type: {
              this.toast.danger('Failed to print bill, please try again');
              break;
            }

            case AppActions.deleteStockFail?.type: {
              this.toast.danger('Failed to delete stock');
              break;
            }

            case AppActions.updateStockFail?.type: {
              this.toast.danger('Failed to update stock');
              break;
            }

            default:
              break;
          }
        })
      ),
    { dispatch: false }
  );
}
