<div class="appContainer">
  @if(isLoading()) {
  <div class="blur-background"></div>
  <mat-spinner></mat-spinner>
  }

  <ng-toast [width]="300" [position]="ToasterPosition.TOP_CENTER" />

  @if ((this.authState$ | async)?.isAuthenticated) {
  <header>
    <app-top-nav></app-top-nav>
  </header>
  }

  <main>
    <div class="centreContainer">
      @if(leftNavState$ | async) {
      <app-left-nav></app-left-nav>
      }

      <router-outlet></router-outlet>
    </div>
  </main>
</div>
