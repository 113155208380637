import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { LeftNavComponent } from './features/left-nav/left-nav.component';
import { TopNavComponent } from './features/top-nav/top-nav.component';
import { Observable, skip } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectLeftNavState } from './ngrx/app.selectors';
import { AsyncPipe } from '@angular/common';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';
import {
  getAllProducts,
  getAllShopsSuccess,
  getAllStocks,
  getAllUsers,
  getAllUsersSuccess,
} from './ngrx/app.actions';
import * as localforage from 'localforage';
import { GetUserInterface } from './interfaces/user.interface';
import { ShopsInterface } from './interfaces/shops.interface';
import { NgToastModule, ToasterPosition } from 'ng-angular-popup';
import { ApiService } from './services/api.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    RouterModule,
    NgToastModule,
    MatProgressSpinnerModule,

    TopNavComponent,
    LeftNavComponent,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private store = inject(Store);
  private oktaAuthStateService = inject(OktaAuthStateService);
  private destroyRef = inject(DestroyRef);
  private oktaAuth = inject(OKTA_AUTH);
  private apiService = inject(ApiService);

  title = 'POS';
  leftNavState$: Observable<boolean> = this.store.select(selectLeftNavState);
  authState$: Observable<AuthState> = this.oktaAuthStateService.authState$;
  isLoading = this.apiService.isLoading;

  ToasterPosition = ToasterPosition;

  ngOnInit(): void {
    this.authState$
      .pipe(takeUntilDestroyed(this.destroyRef), skip(1))
      .subscribe(async (value) => {
        const user = await this.oktaAuth.getUser();

        try {
          if (value?.isAuthenticated) {
            localStorage.setItem(
              'accessToken',
              JSON.stringify(value?.accessToken)
            );

            const users: GetUserInterface[] | null = await localforage.getItem(
              'users'
            );

            const userInfo: GetUserInterface | null = await localforage.getItem(
              'userInfo'
            );

            const allShops: ShopsInterface[] | null = await localforage.getItem(
              'shops'
            );

            if (
              users &&
              userInfo &&
              userInfo.shopId &&
              userInfo.email === value?.accessToken?.claims?.sub &&
              allShops
            ) {
              this.store.dispatch(
                getAllUsersSuccess({ users: users, userEmail: userInfo.email })
              );
              this.store.dispatch(getAllShopsSuccess({ shops: allShops }));
              this.store.dispatch(getAllStocks({ id: userInfo?.shopId?._id }));
              this.store.dispatch(getAllProducts());
            } else {
              this.store.dispatch(getAllUsers({ userEmail: user.email }));
            }
          } else {
            console.error('ID token is undefined or null or Not authenticated');
            await this.apiService.signOut();
          }
        } catch (error) {
          console.error('Error checking userInfo from IndexedDB:', error);
          await this.apiService.signOut();
        }
      });
  }
}
